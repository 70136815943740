import { Text, TextVariant } from '@naf/text';
import { spacing, themeLight } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { format, formatRelative } from 'date-fns';
import nb from 'date-fns/locale/nb';
import React from 'react';
import styled from 'styled-components';
import { QnaEntry, QnaFeedBlockType } from '../../../../../../types/qnaFeedBlockType';
import BlockContent from '../../../block-content/BlockContent';
import { NafLogo } from './NafLogo';
import { RelatedContentList } from './QnaList/RelatedContentList';
import { ShowNewerMessages } from './SnowNewerMessages';

const nbWithCorrectPast: Locale = {
  ...nb,
  formatRelative: (token, date, baseDate, options) => {
    if (token === 'lastWeek') {
      return "eeee 'kl.' p";
    }
    return nb.formatRelative?.(token, date, baseDate, options);
  },
};

type QnaListProps = {
  entries: NonNullable<QnaFeedBlockType['qnaEntries']>;
  amount: number;
  fetchNewer: () => void;
};

function getRelativeDate(time: string) {
  const relativeDate = formatRelative(new Date(time), Date.now(), { weekStartsOn: 1, locale: nbWithCorrectPast });

  return relativeDate.charAt(0).toLocaleUpperCase() + relativeDate.slice(1);
}

const QnaList = ({ entries, amount, fetchNewer }: QnaListProps) => (
  <QnaListContainer>
    {entries.map((entry) => (
      <QnAItem key={entry.publishedTime} entry={entry} />
    ))}
    {amount > 0 ? <ShowNewerMessages fetchNewer={fetchNewer} amount={amount} /> : null}
  </QnaListContainer>
);

const QnAItem: React.FC<{ entry: QnaEntry; omitTimestamp?: boolean }> = ({ entry, omitTimestamp }) => (
  <div>
    {!omitTimestamp && (
      <TimestampContainer>
        <TimestampText
          variant={TextVariant.Tiny}
          title={format(new Date(entry.publishedTime), 'PPPPp', { locale: nb })}
        >
          {getRelativeDate(entry.publishedTime)}
        </TimestampText>
        <TimestampLine />
      </TimestampContainer>
    )}
    <EntryContainer>
      <QuestionContainer>
        <div>
          <Text variant={TextVariant.BodyTextHeader} tag="span">
            {entry.question.qnaMessage.author.name}
          </Text>
          &nbsp;
          <Text variant={TextVariant.BodyText} tag="span">
            spør:
          </Text>
        </div>
        <MessageContainer>
          <BlockContent value={entry.question.qnaMessage.message} />
        </MessageContainer>
      </QuestionContainer>
      <AnswerContainer>
        <AnswerHeader>
          <div>
            <Text variant={TextVariant.BodyTextHeader} tag="span">
              {entry.answer.qnaMessage.author.name}
            </Text>
            &nbsp;
            <Text variant={TextVariant.BodyText} tag="span">
              svarer:
            </Text>
          </div>
          <NafLogo />
        </AnswerHeader>
        <MessageContainer>
          <BlockContent value={entry.answer.qnaMessage.message} />
        </MessageContainer>
        {entry.answer.relatedContent ? <RelatedContentList relatedContent={entry.answer.relatedContent} /> : null}
      </AnswerContainer>
    </EntryContainer>
  </div>
);

const QnaListContainer = styled.div`
  position: relative;
`;

const EntryContainer = styled.div`
  margin-top: ${spacing.space24};

  border: 2px solid ${nafColor.primary.dew};
  border-radius: 2px;
  overflow: hidden;
`;

const QuestionContainer = styled.div`
  padding: ${spacing.space24};
  background: ${nafColor.signature.white};
`;

const AnswerContainer = styled.div`
  padding: ${spacing.space24};
  background-color: ${nafColor.primary.dew};
`;

const AnswerHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

const MessageContainer = styled.div`
  margin-top: ${spacing.space4};
  margin-bottom: ${spacing.space24};

  &:last-child {
    margin-bottom: 0;
  }

  & > p:first-child {
    margin-top: 0;
  }

  & > p:last-child {
    margin-bottom: 0;
  }
`;

const TimestampContainer = styled.div`
  position: relative;
  height: 16px;
  margin-top: ${spacing.space24};
  display: flex;
  align-items: center;
`;

const TimestampLine = styled.hr`
  border: none;
  height: 1px;
  width: 100%;
  margin: auto 0;
  background-color: ${nafColor.neutral.neutral2};
`;

const TimestampText = styled(Text)`
  top: 0;
  left: ${spacing.space20};
  background-color: ${themeLight.background.default};
  color: ${nafColor.neutral.neutral4};
  display: inline-block;
  position: absolute;
  margin: 0;
  padding: 0 ${spacing.space4};
`;

export { QnaList, getRelativeDate, QnAItem };
