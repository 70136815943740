import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Text, TextVariant } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import { breakpoints, spacing } from '@naf/theme';

import { RelatedArticleType } from '../../../../../../../types/articleType';
import { ChargeTestType, DocumentTypeLabel, RangeTestType } from '../../../../../../../types/testType';
import { useDocumentUrlWithNode } from '../../../../../hooks/useDocumentUrl';
import { ApplicationState } from '../../../../../redux/modules/application';
import useSelector from '../../../../../redux/typedHooks';
import { cloudinaryImage } from '../../../../../utils/imageUrl';
import FallbackImage from '../../../../../../assets/images/fallback_image.png';

type TestContentType = ChargeTestType | RangeTestType;
type RelatedContentType = RelatedArticleType | TestContentType;

type RelatedArticleListProps = {
  relatedContent: RelatedContentType[] | null;
};

const RelatedContentList = ({ relatedContent }: RelatedArticleListProps) => {
  const application = useSelector((state) => state.application);

  if (relatedContent !== null) {
    return (
      <ListWrapper>
        {relatedContent.map((content) => (
          <RelatedContent content={content} app={application} key={content.slug} />
        ))}
      </ListWrapper>
    );
  }

  return null;
};

type RelatedContentProps = { content: RelatedContentType; app: ApplicationState };

const RelatedContent = ({ content, app }: RelatedContentProps) => {
  const contentUrl = useDocumentUrlWithNode(content) || '';

  switch (content.type) {
    case 'nafnoArticle':
    case DocumentTypeLabel.nafnoArticleCharge:
    case DocumentTypeLabel.nafnoArticleRange:
    case DocumentTypeLabel.articleRangeReferenceComponent:
    case DocumentTypeLabel.articleChargeReferenceComponent: {
      if (!content?.name || !content?.ingress) return null;

      const contentImage = content?.image?.publicId ? cloudinaryImage(content.image.publicId, app, 200) : FallbackImage;

      return (
        <UnstyledLink to={contentUrl}>
          <ContentWrapper>
            <ImageWrapper>
              <img src={contentImage} alt={content.image?.altText ? `${content.image.altText}` : ''} />
            </ImageWrapper>

            <div>
              <TitleText variant={TextVariant.ArticleTextHeader}>{content.name}</TitleText>
              <IngressText variant={TextVariant.BodyText}>{content.ingress}</IngressText>
            </div>
          </ContentWrapper>
        </UnstyledLink>
      );
    }

    default:
      return null;
  }
};

const ListWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  row-gap: ${spacing.space24};
`;

const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

const ImageWrapper = styled.div`
  // Size is set in ContentWrapper for media query reasons
  aspect-ratio: 16 / 10;
  flex-shrink: 0;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    object-fit: cover;

    min-height: 100%;
    min-width: 100%;
  }
`;

const TitleText = styled(Text)`
  margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const IngressText = styled(Text)`
  margin-top: ${spacing.space8};
  margin-bottom: 0;

  color: ${nafColor.neutral.neutral4};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;

  column-gap: ${spacing.space16};

  & > ${ImageWrapper} {
    height: 112px;
  }

  @media screen and (max-width: ${breakpoints.s}) {
    & > ${ImageWrapper} {
      height: initial;
      width: 90px;
    }

    & ${IngressText} {
      display: none;
    }
  }
`;

export { RelatedContentList };
