import { Text, TextVariant } from '@naf/text';
import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { ArrowUpward } from '@styled-icons/material/ArrowUpward';
import React from 'react';
import styled from 'styled-components';

type ShowNewerMessagesProps = {
  amount: number;
  fetchNewer: () => void;
};

const ShowNewerMessages = ({ fetchNewer, amount }: ShowNewerMessagesProps) => (
  <LoadMoreButton type="button" onClick={() => fetchNewer()}>
    <ButtonText variant={TextVariant.BodyText}>
      Last inn ({amount}) {amount > 1 ? 'nye' : 'ny'}
    </ButtonText>
    <ArrowUpward height="16px" width="16px" />
  </LoadMoreButton>
);

const LoadMoreButton = styled.button`
  height: 32px;
  background-color: ${nafColor.primary.moss};

  border: none;
  border-radius: 16px;

  padding: ${spacing.space4} ${spacing.space12} ${spacing.space4} ${spacing.space16};

  display: flex;
  flex-flow: row nowrap;
  column-gap: ${spacing.space8};
  align-items: center;

  position: absolute;
  left: calc(50% - 146px / 2);
  top: -${spacing.space8};

  cursor: pointer;
`;

const ButtonText = styled(Text)`
  margin: 0;
`;

export { ShowNewerMessages };
